import {
  request
} from '../utils/request'

export function getProvinceList(query) {
  return request(
    '/visitor/region/getProvinceName',
    {},
    { method: 'get' })
}
